import styled from 'styled-components';

export const ImageStyle = styled.div`
  background: url(${(props) => props.$background}) no-repeat bottom center;
  height: 550px;
  padding: 195px 0 0 0;
  @media (max-width: 870px) {
    position: relative;
    height: 245px;
    padding: 110px 35px 35px 35px;
    background-position: top center;
    background-size: 900px auto;
  }
`;
export const Heading = styled.h1`
  width: 430px;
  margin: 0 auto;
  padding: 30px;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  color: #00539d;
  background: #ffc905;
  border-radius: 10px;
  @media (max-width: 870px) {
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 20px;
    font-size: 30px;
    line-height: 35px;
  }
`;
